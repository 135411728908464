import { MediaQueries } from '@js/libs/media-queries';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config.js'

const { theme } = resolveConfig(tailwindConfig);

const screens = Object.assign(
  theme.screens, {
    reducedMotion: `(prefers-reduced-motion: reduce)`
  }
);

const m = new MediaQueries( screens );
export const media = m;

export default { media: m };
