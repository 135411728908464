import Slideshow from "@js/libs/slideshow";
import { docReady, qsa } from "@js/libs/helpers";

docReady(() => {
  const heroSlideshow = document.querySelector('[data-slideshow="hero"]');
  const heroSlideshowCaption = document.querySelector('[data-slideshow="hero-caption"]');

  setTimeout(() => {
    if (heroSlideshow) {
      let container = heroSlideshow;
      let slide = '[data-slide]';
      let ssImage = new Slideshow({
        container,
        slide,
      });

      ssImage.options = {
        duration: 12000,
        z: 1.1,
        playNextAt: 5000,
        fadeInDelay:  1000,
        fadeOutDelay: 1000,
        fadeDuration: 1000,
      };

      window.ss = ssImage;
      ssImage.start();

      // Parallax Effect
      window.addEventListener('scroll', () => {
        let offset = window.pageYOffset;
        let speed = 0.5;
        heroSlideshow.style.transform = `translate3d(0, ${Math.floor(offset * speed) }px, 0)`;
      })
    }

    if (heroSlideshowCaption) {
      let container = heroSlideshowCaption;
      let slide = '[data-slide]';
      let ssCaption = new Slideshow({
        container,
        slide,
      });

      ssCaption.options = {
        duration: 12000,
        z: 1,
        playNextAt: 5000,
        fadeInDelay:  1200,
        fadeOutDelay: 800,
        fadeDuration: 800,
      };

      window.ss = ssCaption;
      ssCaption.start();
    }
  }, 2000)
})
