import Accordion from 'accordion-js';

export const accordion = function (selector, opts) {
  return new Accordion(selector, {
    duration: 300,
    showMultiple: true,
    ...opts
  });
};

export default accordion;
