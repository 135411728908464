/* eslint-disable quote-props */
// Convert a unit to rem, eg...
// p-4 -> 1rem;
const rem = (i) => `${i * 4 / 16}rem`;

const makeRange = (from, to, step, cb) => {
  const config = {};
  for (let i = from; i <= to; i += step) {
    config[i] = cb(i);
  }
  return config;
};

const spacing = {
  px: '1px',
  '2px': '2px',
  '1em': '1em',
  // It's a lot. But, this is the design studio we're in,
  // you need a lot of random sizes, so let's just lean on purge...
  ...makeRange(0, 80, 1, rem),
  ...makeRange(80, 200, 4, rem)
};

const columns = {
  '1/2': '50%',
  '2/2': '100%',
  '1/3': '33.333333%',
  '2/3': '66.666667%',
  '3/3': '100%',
  '1/4': '25%',
  '2/4': '50%',
  '3/4': '75%',
  '4/4': '100%',
  '1/5': '20%',
  '2/5': '40%',
  '3/5': '60%',
  '4/5': '80%',
  '5/5': '100%',
  '1/6': '16.666667%',
  '2/6': '33.333333%',
  '3/6': '50%',
  '4/6': '66.666667%',
  '5/6': '83.333333%',
  '6/6': '100%',
  '1/10': '10%',
  '2/10': '20%',
  '3/10': '30%',
  '4/10': '40%',
  '5/10': '50%',
  '6/10': '60%',
  '7/10': '70%',
  '8/10': '80%',
  '9/10': '90%',
  '10/10': '100%',
  '1/12': '8.333333%',
  '2/12': '16.666667%',
  '3/12': '25%',
  '4/12': '33.333333%',
  '5/12': '41.666667%',
  '6/12': '50%',
  '7/12': '58.333333%',
  '8/12': '66.666667%',
  '9/12': '75%',
  '10/12': '83.333333%',
  '11/12': '91.666667%',
  '12/12': '100%',
};

export default {
  prefix: '',
  important: false,
  separator: ':',
  content: [
    './templates/**/*.twig',
  ],
  theme: {
    aspectRatio: {
      '16x9': 16 / 9,
      '3x2': 3 / 2,
      '1x1': 1,
      '31x33': 31 / 33,
      '32x21': 32 / 21,
      '34x19': 34 / 19,
      '44x19': 44 / 19,
    },
    screens: {
      mobile: {max: '639px'},
      'below-lg': {max: '1023px'},
      xs: {min: '480px'},
      sm: {min: '640px'},
      md: {min: '768px'},
      lg: {min: '1024px'},
      xl: {min: '1280px'},
      '2xl': {min: '1440px'},
      '3xl': {min: '1600px'},
      '4xl': {min: '1920px'},
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      inherit: 'inherit',

      // picked from design
      orange: {
        DEFAULT: 'rgba(216,84,66,1)',
        ...makeRange(10, 90, 10, i => `rgba(216,84,66,${i / 100})`)
      },
      lightblue: {
        DEFAULT: 'rgba(74,159,201,1)',
        ...makeRange(10, 90, 10, i => `rgba(74,159,201,${i / 100})`)
      },
      darkblue: {
        DEFAULT: 'rgba(30,38,65,1)',
        ...makeRange(10, 90, 10, i => `rgba(30,38,65,${i / 100})`)
      },
      green: {
        DEFAULT: 'rgba(80,168,159,1)',
        ...makeRange(10, 90, 10, i => `rgba(80,168,159,${i / 100})`)
      },

      'grey-193': 'rgba(193,194,193,1)',
      'white-245': 'rgba(245,245,245,1)',
      'grey-50': 'rgba(50,50,49,1)',
      'background-grey': 'rgba(245, 245, 245, 1)',
      'offwhite': 'rgba(255,254,243,1)',
      'white-251': 'rgba(251,251,251,1)',
      'darkorange': 'rgba(202, 60, 46, 1)',
      'grey-94': 'rgba(94, 94, 93, 1)',
      'slate': 'rgba(76, 89, 104, 1)',

      // Use CSS vars for themes:
      // theme: 'var(--theme-color, rgb(30, 34, 169) )',

      // Use make range to create a range of opacity, eg
      black: {
        DEFAULT: 'rgba(0,0,0,1)',
        // will produce black-10, black-20, eg rgba(0,0,0,0.1) and so on....
        ...makeRange(10, 90, 10, i => `rgba(0,0,0,${i / 100})`)
      },
      white: {
        DEFAULT: 'rgba(255,255,255,1)',
        ...makeRange(10, 90, 10, i => `rgba(255,255,255,${i / 100})`)
      },
    },

    spacing: {
      ...spacing,
      ...columns
    },

    namedWidths: {
      'xs': '20rem',
      'sm': '24rem',
      'md': '28rem',
      'lg': '32rem',
      'xl': '36rem',
      '2xl': '42rem',
      '3xl': '48rem',
      '4xl': '56rem',
      '5xl': '64rem',
      '6xl': '72rem',
      '7xl': '80rem',
      full: '100%',
      screenw: '100vw',
      screenh: '100vh',
      container: '85.625rem',
    },
    borderColor: theme => ({
      ...theme('colors'),
      DEFAULT: 'currentColor',
    }),
    fontFamily: {
      sans: ['SuisseIntl', 'system-ui', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', '"Noto Sans"', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"', '"Noto Color Emoji"',],
      serif: ['Joane', 'Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif'],
      mono: ['Menlo', 'Monaco', 'Consolas', '"Liberation Mono"', '"Courier New"', 'monospace'],
    },
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      md: '1.0625rem',
      lg: '1.125rem',
      lgr: '1.1875rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
      // Fonts from 10px to 60px, in REM:
      // eg text-10, text-11 ... text-60
      ...makeRange(10, 60, 1, i => `${i / 16}rem`),
      // text-62, text-64 etc...
      ...makeRange(62, 70, 2, i => `${i / 16}rem`),
    },
    // Look in `templates/_includes/fonts.twig` for details about
    // why it's done this way..
    fontWeight: {
      // Joane - our serif - we know only as 400 or 600... right?
      400: '400',
      600: '600',
      // SuisseIntl, our sans-serif, has named weights. Be sure to include
      // the font in templates/_includes/fonts.twig
      ultralight: '100',
      // thin: '200',
      light: '300',
      // regular: '400',
      book: '500',
      medium: '600',
      // semibold: '700',
      // bold: '800',
      // black: '900',
    },
    letterSpacing: {
      tighter: '-0.05em',
      tight: '-0.025em',
      normal: '0',
      wide: '0.025em',
      wider: '0.05em',
      widest: '0.16em',
    },

    // For all "sizes", use the same approach,
    // but it's a lot going in. It's flexible, and purge helps us,
    // so I think it's fine, but you may wish for clarity.
    margin: (theme, {negative}) => ({
      auto: 'auto',
      ...theme('spacing'),
      ...negative(theme('spacing')),
    }),
    width: theme => ({
      auto: 'auto',
      ...theme('spacing'),
      ...theme('namedWidths'),
    }),
    height: theme => ({
      ...theme('spacing'),
      ...theme('namedWidths'),
    }),
    padding: theme => ({
      ...theme('spacing'),
      ...theme('namedWidths'),
    }),
    maxHeight: theme => ({
      full: '100%',
      ...theme('spacing'),
      ...theme('namedWidths'),
    }),
    maxWidth: theme => ({
      ...theme('spacing'),
      ...theme('namedWidths'),
    }),
    minHeight: theme => ({
      ...theme('spacing'),
      ...theme('namedWidths'),
    }),
    minWidth: theme => ({
      ...theme('spacing'),
      ...theme('namedWidths'),
    }),
    opacity: {
      ...makeRange(0, 100, 5, o => `${o / 100}`)
    },
  },
  variants: {
    aspectRatio: ['responsive'],
    backgroundColor: ['responsive', 'hover', 'focus', 'focus-within', 'group-hover'],
    borderColor: ['responsive', 'hover', 'focus', 'group-hover'],
    boxShadow: ['responsive', 'hover', 'focus', 'focus-within', 'group-hover'],
    fill: ['responsive', 'group-hover'],
    fontWeight: ['responsive', 'hover', 'focus'],
    opacity: ['responsive', 'hover', 'focus', 'group-hover'],
    outline: ['responsive', 'focus'],
    textColor: ['responsive', 'group-hover', 'hover', 'focus'],
    textDecoration: ['responsive', 'hover', 'group-hover', 'focus'],
  },
  corePlugins: {
    container: false
  },
  plugins: [

    // ASPECT RATIO:
    function({theme, addUtilities, variants}) {

      const base = {
        height: '0',
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
      };

      addUtilities({
        '.ar-base': base
      });

      Object.entries(theme('aspectRatio')).forEach(([key, value]) => {
        addUtilities({
          [`.ar-${key}`]: {
            ...base,
            paddingBottom: `${100 / (value)}%`,
          }
        }, {
          variants: variants('aspectRatio')
        });
      });

    },
  ],
};
