import Flickity from 'flickity';

export const standardCarousel = function (carousel, selector, options = {}) {

  const mergedOptions = Object.assign({
    cellSelector: selector,
    pageDots: false,
    adaptiveHeight: false,
    wrapAround: false,
    setGallerySize: false,
  }, options);

	return new Flickity(carousel, mergedOptions);
};

export default standardCarousel;
