import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

document.addEventListener('lazybeforeunveil', function (e) {
  const classList = e.target.getAttribute('data-class-list');

  // Add css classes
  if (classList) {
    e.target.classList.add(classList);
  }
});
