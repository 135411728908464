import { qsa, docReady } from "@js/libs/helpers";
import { media } from "@js/parts/media";

docReady(() => {

  let reducedMotion = media.on('reducedMotion', matches => reducedMotion = matches, true);

  const fn = entries => {
    entries.forEach(({isIntersecting, target}) => {
      if (isIntersecting) {
        activate(target);
      } else {
        deactivate(target);
      }
    });
  };

  let thresholdVal;

  if (media.matches('mobile')) {
    thresholdVal = 0.3;
  } else {
    thresholdVal = 0.9;
  }

  let paintUpFns = {};

  let uid = 0;
  const _uuid = () => {
    uid += 1;
    return uid;
  };

  const makeFn = (target, id) => {
    let lastY;
    let movement;
    let offset;
    const factor = 0.25;
    const reset = () => {
      lastY = false;
      movement = 0;
      offset = 100;
    };

    reset();

    return {
      reset,
      fn: () => {
        if (lastY === false) {
          lastY = window.pageYOffset;
        }

        movement += factor * (Math.abs(window.pageYOffset - lastY));
        const y = Math.min(offset, Math.max(0, offset - movement));
        target.style.transform = `translateY(${y * 0.05}vh)`;

        lastY = window.pageYOffset;
      }
    }
  };

  const activate = target => {
    target.classList.add('js-in-view');

    // This is admittedly a bit hack-ish. But it works. Meh.
    if(target.getAttribute('data-transition') == 'playgifs') {
      const delay = 400;
      let gifs = target.querySelectorAll('img[data-src]');
      gifs.forEach((el, i, list) => {
        let src = el.getAttribute('data-src');
        el.removeAttribute('data-src');
        setTimeout(function() {
          el.setAttribute('src', src);
        }, delay * i);
      });
    }

    const {uid} = target.dataset;
    if (uid && paintUpFns[uid]) {
      paintUpFns[uid].reset();
      paintUpFns[uid].active = true;
    }
  };

  const deactivate = target => {
    const {uid} = target.dataset;
    if (uid && paintUpFns[uid]) {
      paintUpFns[uid].reset();
      paintUpFns[uid].active = false;
    }
  };


  let transitionCount = 0;
  qsa('[data-parallax]', target => {
    transitionCount += 1;
    observer.observe(target);

    if (!target.dataset.uid) {
      const uid = _uuid();
      target.dataset.uid = uid;
    }

    const {reset, fn} = makeFn(target, uid);

    paintUpFns[uid] = {
      fn,
      reset,
      uid,
      active: false
    }

  });

  qsa('[data-transition]', target => {
    let observer = new IntersectionObserver(fn, {
      root: null,
      rootMargin: '0px',
      threshold: target.dataset.threshold || thresholdVal,
    });

    observer.observe(target);
  });

  const paintUps = () => {
    Object.entries(paintUpFns).forEach(([id, paintup]) => {
      if (paintup.active) {
        paintup.fn();
      }
    });
  };

  const disableTransition = document.getElementById('disable-transition');
  if (disableTransition || transitionCount > 0) {
    const loop = () => {
      requestAnimationFrame(() => {
        if (disableTransition) {
          disableTransition.style.transform = `translateY(${Math.min(0, window.pageYOffset * -0.125)}px)`;
        }
        paintUps();

        // Just kill it. Don't put it back, no need.
        if (!reducedMotion) {
          loop();
        }
      });
    };
    loop();
  }
});
