import accordion from "@js/libs/accordion";
import { docReady, qsa } from "@js/libs/helpers";

docReady(() => {
  qsa('.accordion-container', el => {
    let wordOrig = 'more';
    let wordSwap = 'less';
    let ac = new accordion(el, {
      beforeOpen: (currEl) => {
        let openIcon = currEl.querySelector('.toggle-icon-open');
        let closeIcon = currEl.querySelector('.toggle-icon-close');
        let wordEl = currEl.querySelector('.toggle-word');
        openIcon.style.display = 'none';
        closeIcon.style.display = 'inline';
        wordEl.innerHTML = wordSwap;
      },
      beforeClose: (currEl) => {
        let openIcon = currEl.querySelector('.toggle-icon-open');
        let closeIcon = currEl.querySelector('.toggle-icon-close');
        let wordEl = currEl.querySelector('.toggle-word');
        openIcon.style.display = 'inline';
        closeIcon.style.display = 'none';
        wordEl.innerHTML = wordOrig;
      }
    });

    // Open an accordion element if its id matches URL hash
    if(document.location.hash) {
      let els = document.getElementsByClassName('ac-header');
      let openIdx;

      Array.from(els).forEach((el, idx) => {
        if(el.id === document.location.hash.substring(1)) {
          openIdx = idx;
        }
      });

      if(openIdx !== undefined) {
        ac.open(openIdx);
      }
    }
  });
});
