import standardCarousel from "@js/libs/standard-carousel";
import { docReady, qsa } from "@js/libs/helpers";
import { media } from "@js/parts/media";

const getData = (el, attr) => {
  return el.getAttribute(attr);
};

docReady(() => {
  const initFns = [];

  qsa('[data-carousel="logo"]', el => {
    let flkty;
    const breakpoint = getData(el, 'data-breakpoint');

    let options = {
      prevNextButtons: false,
      pageDots: true,
      wrapAround: true,
      setGallerySize: true,
      autoPlay: 4000,
      pauseAutoPlayOnHover: false,
    };

    const init = () => {
      if (flkty && !breakpoint) {
        return;
      }

      if (flkty) {
        flkty.destroy();
      }

      if (breakpoint && !media.matches(breakpoint)) {
        return;
      }

      flkty = standardCarousel(el, '[data-slide]', options);
    };

    initFns.push(init);
  });

  const initAll = () => {
    initFns.forEach(fn => fn());
  };

  // initialize carousel when in view
  const carouselWrapper = document.querySelectorAll('[data-carousel="logo"]');
  let observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        initAll();
        window.addEventListener('resize', initAll);
      }
    });
  });

  carouselWrapper.forEach(wrapper => {
    observer.observe(wrapper);
  })

})
