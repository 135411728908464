/* CSS */
import "@js/parts/css.js";

/* Our App */
import "@js/parts/lazyloading";
import "@js/parts/logo-carousel";
import "@js/parts/slideshow-carousel";
import "@js/parts/hero-slideshow";
import "@js/parts/transitions";
import "@js/parts/accordion";

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
